/*
 * @Author: your name
 * @Date: 2021-08-23 14:25:37
 * @LastEditTime: 2021-09-02 14:01:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /qlchat-edu-offical-frontend/pages/pc-client/components/Layout/LayoutHead.tsx
 */
import React from 'react';
import { connect } from 'react-redux';
import Picture from '../../../../components/picture/Picture';

/** props */
interface IProps {
	categoryList?: any;
	topicListEl?: any;
	region?: string;
	activeTab?: string;
	tabArr?: any;
	tabClick?: any;
}

/**
 * 页眉
 */
class LayoutHead extends React.PureComponent<IProps> {
	public state: any = {
		tabList: [
			{
				name: '首页',
				code: 'home',
				url: '/',
			},
			{
				name: '瑜伽学堂',
				code: 'category_75182625',
				url: '/qlchat-edu/category-module/75182625.html',
			},
			{
				name: '功法学堂',
				code: 'category_393378225',
				url: '/qlchat-edu/category-module/393378225.html',
			},
			{
				name: '养生学堂',
				code: 'category_99160584',
				url: '/qlchat-edu/category-module/99160584.html',
			},
			{
				name: '摄影学堂',
				code: 'category_24671985',
				url: '/qlchat-edu/category-module/24671985.html',
			},
			{
				name: '更多课程',
				code: 'more-courses',
				url: '/qlchat-edu/more-courses',
			},
			// {
			// 	name: '精选课程',
			// 	code: 'topic',
			// 	url: '/',
			// },
			// {
			// 	name: '俱乐部广场',
			// 	code: 'community',
			// 	url: '/qlchat-edu/community',
			// },
			// {
			// 	name: '联系我们',
			// 	code: 'contact',
			// 	url: '/',
			// },
			// {
			// 	name: '关于兴趣岛',
			// 	code: 'about',
			// 	url: '/about',
			// },
			// {
			// 	name: '加入我们',
			// 	code: 'join',
			// 	url: 'https://www.liepin.com/company/8975743',
			// },
		],
		active: '',
	};

	/**
	 * 客户端调用获取数据
	 *
	 * @memberof HomeTraining
	 */
	public async componentDidMount(): Promise<any> {
		let tab: any = sessionStorage.getItem('edu-home-active-tab');
		if (tab) {
			tab = JSON.parse(tab);
			this.setState(
				{
					active: tab.code,
				},
				(): any => {
					if (!tab.isCurUrl) {
						this.clickTabToJump(this.state.tabList.filter((item): any => item.code === tab.code)[0]);
					}
				}
			);
		}

		return {};
	}

	/**
	 * IE浏览器判断
	 */
	public iEVersion = (): any => {
		const userAgent: any = navigator.userAgent; // 取得浏览器的userAgent字符串
		const isIE: any = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1; // 判断是否IE<11浏览器
		const isEdge: any = userAgent.indexOf('Edge') > -1 && !isIE; // 判断是否IE的Edge浏览器
		const isIE11: any = userAgent.indexOf('Trident') > -1 && userAgent.indexOf('rv:11.0') > -1;
		if (isIE) {
			return true;
		} else if (isEdge) {
			return true;
		} else if (isIE11) {
			return true;
		} else {
			return false;
		}
	};

	/**
	 * @description tabclick
	 */
	public clickTabToJump: any = (item): void => {
		const { tabClick, tabArr } = this.props;
		if (tabClick && tabArr) {
			tabClick(item);

			return;
		}
		let path: String = location.pathname;
		let isCurUrl: Boolean = path === item.url;
		item.isCurUrl = isCurUrl;
		if (item.code === 'home' || item.code.includes('category_') || item.code === 'more-courses') {
			sessionStorage.setItem('edu-home-active-tab', JSON.stringify(item));
			if (isCurUrl) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
			} else {
				location.href = item.url;
			}
		} else if (item.code === 'topic') {
			sessionStorage.setItem('edu-home-active-tab', JSON.stringify(item));
			if (isCurUrl) {
				if (!this.iEVersion()) {
					window.scrollTo({ top: 612, behavior: 'smooth' });
				} else {
					document.getElementById(item.code).scrollIntoView({ behavior: 'smooth' });
				}
			} else {
				location.href = item.url;
			}
		} else if (item.code === 'contact') {
			sessionStorage.setItem('edu-home-active-tab', JSON.stringify(item));
			if (isCurUrl) {
				document.getElementById(item.code).scrollIntoView({ behavior: 'smooth' });
			} else {
				location.href = item.url;
			}
		} else {
			if (!(isCurUrl)) {
				if (item.code === 'community' || item.code === 'about') {
					sessionStorage.removeItem('edu-home-active-tab');
				}
				location.href = item.url;
			}
		}
		this.setState({
			active: item.code,
		});
	};
	/**
	 * render
	 */
	public render(): any {
		const { activeTab, tabArr } = this.props;
		const { tabList, active } = this.state;
		const list: any = tabList || tabArr;
		const activeBtn: any = active || activeTab || 'home';

		return (
			<div id='p-layout-head' className="p-layout-head">
				<div className="wrap">
					<a href="/">
						<img src="https://img.qlchat.com/qlLive/activity/image/6CN9M74R-U3R4-CJLE-1709892287468-KOZAOSMVM75D.png?x-oss-process=image/format,webp" className="ql-logo" alt="" />
						{/* <img src="https://img.qlchat.com/qlLive/activity/image/D9KVNT5F-ZA1Z-MHW1-1717049642700-3XO3TXKBP8HJ.png" className="ql-logo-66" alt="兴趣岛66节" /> */}
					</a>
					<div className="p-layout-head-tab-link">
						{list.map((item: any, index: any): any => {
							return (
								<div
									key={index}
									className={`tab-btn ${activeBtn === item.code ? 'active' : ''}`}
									onClick={(): any => {
										this.clickTabToJump(item);
									}}>
									{item.name}
								</div>
							);
						})}
						<div className={`download-app`} >
							下载APP
							<div className='download-app-popover'>
								<img className='qr-code' src="https://img.qlchat.com/qlLive/activity/image/SV8V8WAX-EBY8-VJE8-1702605215787-D3G38JMEBNVF.png?x-oss-process=image/format,webp" />
								<div>扫码下载APP</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

/**
 * mapStateToProps
 */
function mapStateToProps(state: any): any {
	return {};
}

const mapActionToProps: any = {};

export default connect(mapStateToProps, mapActionToProps)(LayoutHead);
